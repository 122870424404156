<template>
	<div class="pui-form">
		<iframe
			width="100%"
			height="100%"
			:src="proyeccionesUrl"
			frameborder="0"
			v-if="this.proyeccionesUrl"
			scrolling="yes"
		></iframe>
		<!-- footer -->
		<pui-form-footer />
	</div>
</template>

<script>
export default {
	name: 'externalproy',
	data() {
		return {
			proyeccionesUrl: null
		};
	},
	mounted() {
		this.proyeccionesUrl = 'https://proyeccionesitap.alquemy.es';
	}
};
</script>

<style scoped>
</style>
